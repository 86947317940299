.data-list {
	ul {
		list-style: none;
	}
	li {
		position: relative;
	    padding: 0.75rem 1.25rem;
	    border: 1px solid transparent;
	        border-top-color: transparent;
	        border-right-color: transparent;
	        border-bottom-color: transparent;
	        border-bottom-style: solid;
	        border-bottom-width: 1px;
	        border-left-color: transparent;
	    border-radius: 0.25rem;

		color: #0c5460;
		background-color: #d1ecf1;
		border-color: #bee5eb;

		a{
			color : #000000;
		}
	}
	li.focus-list {
		background-color: #58ACFA !important;
	}
}